import React from "react"
import RemoveIcon from "../../../images/fly_cart_remove.svg"

const CartCouponItem = ({
  item,
  setUpdateCartProcessing,
  handleRemoveCouponClick,
  textRemoveFromCart,
}) => {
  return (
    <div className="cartCoupon__row">
      <div className="cartCoupon__td cartCoupon__td--remove">
        <button
          className="cart-remove-item"
          onClick={event => {
            handleRemoveCouponClick(event, item.code)
            setUpdateCartProcessing(true)
          }}
        >
          <RemoveIcon />
          <span>{textRemoveFromCart}</span>
        </button>
      </div>

      <div className="cartCoupon__td cartCoupon__td--name">
        {item.name}
        {/* {item.couponForProduct && (
          <span>{item.couponForProduct}</span>
        )}
        {item.couponForCategory && (
          <span>{item.couponForCategory}</span>
        )} */}
      </div>

      <div className="cartCoupon__td cartCoupon__td--discout">
        <span dangerouslySetInnerHTML={{ __html: item.discountAmountType }} />
      </div>
    </div>
  )
}

export default CartCouponItem
