import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { removeHost, isUserLoggedIn } from "../../utils/functions";
import './style.scss';
import Step from '../../images/stepIndyficatorCheckbox.svg';

const OrderStepIndyficator = ({ orderPath, activeStep }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  let stepIndex = 0;

  useEffect(() => {
    const auth = isUserLoggedIn();

    if (!isEmpty(auth)) {
      setLoggedIn(true);
    }

  }, [loggedIn]);

  return (
    <section className="orderStepIndyficator">
      <div className="container">
        <nav >
          <ul className="orderStepIndyficator__wrapper">
            {orderPath?.map((step, index) => {
              if (loggedIn && step.type === 'login') {
                return '';
              }
              stepIndex++;
              return (
                <li key={'orderStepIndyficator__' + index} className={`orderStepIndyficator__item ${activeStep === index ? 'orderStepIndyficator__item--active' : ""} ${activeStep > index ? 'orderStepIndyficator__item--pass' : ""}`}>
                  {activeStep === 3 ? (
                    activeStep === index ? (
                      <>
                        <span aria-current="page">
                          <span className="orderStepIndyficator__item__index">{stepIndex}</span>
                          <span className="orderStepIndyficator__item__text">
                            {step.text.title}
                          </span>
                        </span>
                      </>
                    ) : (
                      <>
                        <span aria-current="page">
                          <span className="orderStepIndyficator__item__index"><Step /></span>
                          <span className="orderStepIndyficator__item__text">
                            {step.text.title}
                          </span>
                        </span>
                      </>
                    )
                  ) : (
                    activeStep >= index ? (
                      activeStep > index ? (
                        <>
                          <Link to={removeHost(step.text.url)}>
                            <span className="orderStepIndyficator__item__index"><Step /></span>
                            <span className="orderStepIndyficator__item__text">
                              {step.text.title}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={removeHost(step.text.url)}>
                            <span className="orderStepIndyficator__item__index">{stepIndex}</span>
                            <span className="orderStepIndyficator__item__text">
                              {step.text.title}
                            </span>
                          </Link>
                        </>
                      )
                    ) : (
                      <>
                        <span aria-current="page">
                          <span className="orderStepIndyficator__item__index">{stepIndex}</span>
                          <span className="orderStepIndyficator__item__text">
                            {step.text.title}
                          </span>
                        </span>
                      </>
                    )
                  )}

                </li>
              )
            }
            )}
          </ul>
        </nav>
      </div>
    </section>
  )
}
export default OrderStepIndyficator
