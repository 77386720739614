import React, { useState } from "react"
import { v4 } from "uuid"
import { getUpdatedItems } from "../../../utils/functions"
import { LazyLoadImage } from "react-lazy-load-image-component"
import isEmpty from "validator/es/lib/isEmpty"
import RemoveIcon from "../../../images/fly_cart_remove.svg"
import Link from "gatsby-link"

const CartItem = ({
  item,
  products,
  setUpdateCartProcessing,
  updateCartProcessing,
  handleRemoveProductClick,
  updateCart,
  textRemoveFromCart,
}) => {
  const [productCount, setProductCount] = useState(item.qty)

  /*
   * When user changes the qty from product input update the cart in localStorage
   * Also update the cart in global context
   *
   * @param {Object} event event
   *
   * @return {void}
   */
  const handleQtyChange = (event, cartKey, type) => {
    if (typeof window !== "undefined") {
      event.stopPropagation()
      let newQty

      // If the previous update cart mutation request is still processing, then return.
      if (
        updateCartProcessing ||
        ("decrement" === type && 1 === productCount)
      ) {
        return
      }

      if (!isEmpty(type)) {
        newQty = "increment" === type ? productCount + 1 : productCount - 1
      } else {
        // If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
        newQty = event.target.value ? parseInt(event.target.value) : 1
      }

      // Set the new qty in state.
      setProductCount(newQty)

      if (products.length) {
        const updatedItems = getUpdatedItems(products, newQty, cartKey)

        updateCart({
          variables: {
            input: {
              clientMutationId: v4(),
              items: updatedItems,
            },
          },
        })
      }
    }
  }
console.log(item);
  return (
    <div className="cartItems__row">
      <div className="cartItems__td cartItems__td--remove">
        <button
          className="cart-remove-item"
          onClick={event => {
            handleRemoveProductClick(event, item.cartKey, products)
            setUpdateCartProcessing(true)
          }}
        >
          <RemoveIcon />
          <span>{textRemoveFromCart}</span>
        </button>
      </div>
      <div className="cartItems__td cartItems__td--img">
        <figure>
          <Link to={item.uri}>
            <LazyLoadImage
              alt={item.image.title}
              src={!isEmpty(item.image.sourceUrl) ? item.image.sourceUrl : ""} // use normal <img> attributes as props
              effect="blur"
            />
          </Link>
        </figure>
      </div>
      <div className="cartItems__td cartItems__td--name">
        <Link to={item.uri}>{item.name}</Link>
        {item.promotionText && <span style={{fontSize:"12px", color: "#628942", fontWeight: "600"}} dangerouslySetInnerHTML={{__html: item.promotionText}} />}
      </div>

      <>
        <div className="cartItems__td cartItems__td--price">
          {/* {!item.isSmartCoupon ? ( */}
          <span
            className="cart-product-price"
            dangerouslySetInnerHTML={{ __html: item.priceSingleProduct }}
          />
          {/* ) : (
            ""
          )} */}
        </div>
        <div className="cartItems__td cartItems__td--quantity">
          {!item.isSmartCoupon ? (
            <>
              <button
                className="increment-btn"
                onClick={event => {
                  handleQtyChange(event, item.cartKey, "decrement")
                  setUpdateCartProcessing(true)
                }}
              >
                -
              </button>
              <input
                type="number"
                min="1"
                style={{
                  textAlign: "center",
                  width: "60px",
                  paddingRight: "0",
                }}
                data-cart-key={item.cartKey}
                className={`woo-next-cart-qty-input form-control ${
                  updateCartProcessing ? "woo-next-cart-disabled" : ""
                } `}
                value={productCount}
                onChange={event => {
                  handleQtyChange(event, item.cartKey, "")
                  setUpdateCartProcessing(true)
                }}
              />
              <button
                className="decrement-btn"
                onClick={event => {
                  handleQtyChange(event, item.cartKey, "increment")
                  setUpdateCartProcessing(true)
                }}
              >
                +
              </button>
            </>
          ) : (
            item.qty
          )}
        </div>
        <div className="cartItems__td cartItems__td--allPrice">
          <span
            className="cart-total-price"
            dangerouslySetInnerHTML={{ __html: item.totalPrice }}
          />
        </div>
      </>
    </div>
  )
}

export default CartItem
