import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { getUpdatedItems } from "../../../utils/functions";
import { useMutation } from "@apollo/client";
import UPDATE_CART from "../../../mutations/update-cart";
import REMOVE_COUPON from "../../../mutations/remove-coupon";
import APPLY_COUPON from "../../../mutations/apply-coupon";
// import GET_CART from "../../../queries/get-cart";
import CartItem from "./CartItem";
import Coupon from "./Coupon";
import { v4 } from "uuid";
import './style.scss';
import Spin from "../../spin/Spin";
import { isEmpty } from 'lodash';
import CartCouponItem from "./CartCouponItem";


const CartItems = ({
  couponButton,
  couponPlaceholder,
  headingYorProducts,
  tabelHeadingAllPrice,
  tabelHeadingPrice,
  tabelHeadingProduct,
  tabelHeadingQuantity,
  textRemoveFromCart,
  tabelHeadingCouponName,
  tabelHeadingCouponDiscout,
  refetchGetCart,
  updateCartProcessing,
  setUpdateCartProcessing
}) => {

  // const { cart, setCart } = useContext(AppContext);
  const { cart, } = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);



  const handleRemoveProductClick = (event, cartKey, products) => {
    event.stopPropagation();
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0;
      const updatedItems = getUpdatedItems(products, newQty, cartKey);

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      });
    }
  };

  const handleRemoveCouponClick = (event, code) => {
    event.stopPropagation();
    if (code) {
      removeCoupon({
        variables: {
          input: {
            clientMutationId: v4(),
            codes: code,
          },
        },
      });
    }
  }

  const [aplayCoupon, { data: couponData, error: couponError }] = useMutation(
    APPLY_COUPON,
    {

      onCompleted: () => {
        setErrorMessage('');
        refetchGetCart();
      },
      onError: (error) => {

        if (error) {
          if (!isEmpty(error)) {
            setUpdateCartProcessing(false);
            setErrorMessage(error.graphQLErrors[0].message);
            setTimeout(() => {
              setErrorMessage('');
            }, 5000);
          }
        }
      },
    }
  );

  // Update Cart Mutation.
  const [updateCart] = useMutation(
    UPDATE_CART,
    {
      onCompleted: () => {
        refetchGetCart();
      },
      onError: (error) => {
        console.log(error.graphQLErrors)
        if (error?.graphQLErrors) {
          setRequestError(error?.graphQLErrors[0].message);
        }
      },
    }
  );

  // remove Coupon Mutation.
  const [removeCoupon] = useMutation(
    REMOVE_COUPON,
    {
      onCompleted: () => {
        refetchGetCart();
      },
      onError: (error) => {
        if (error) {
          setRequestError(error?.graphQLErrors[0].message);
        }
      },
    }
  );

  return (
    <section className="cartItems">
      <div className="cartItems__wrapper">
        <h2 className="cartItems__heading heading--dash heading--normal">{headingYorProducts}</h2>
        <div className="cartItems__table">
          <div className="cartItems__header">
            <div className="cartItems__row cartItems__row">
              <div className="cartItems__th cartItems__td--remove"></div>
              <div className="cartItems__th cartItems__td--img"></div>
              <div className="cartItems__th cartItems__td--name">{tabelHeadingProduct}</div>
              <div className="cartItems__th cartItems__td--price">{tabelHeadingPrice}</div>
              <div className="cartItems__th cartItems__td--quantity">{tabelHeadingQuantity}</div>
              <div className="cartItems__th cartItems__td--allPrice">{tabelHeadingAllPrice}</div>
            </div>
          </div>
          <div className="cartItems__body">
            {cart?.products?.length &&
              cart?.products.map((item, index) => (
                <CartItem
                  textRemoveFromCart={textRemoveFromCart}
                  key={item.cartKey}
                  item={item}
                  setUpdateCartProcessing={setUpdateCartProcessing}
                  products={cart.products}
                  handleRemoveProductClick={handleRemoveProductClick}
                  updateCart={updateCart}
                />
              ))}
          </div>
          {cart?.coupons?.length ? (
            <div className="cartCoupon">
              <div className="cartCoupon__header">
                <div className="cartCoupon__row">
                  <div className="cartCoupon__td cartCoupon__td--remove">
                  </div>
                  <div className="cartCoupon__td cartCoupon__td--name">
                    {tabelHeadingCouponName}
                  </div>
                  <div className="cartCoupon__td cartCoupon__td--discout">
                    {tabelHeadingCouponDiscout}
                  </div>
                </div>
              </div>
              <div className="cartCoupon__body">
                {cart?.coupons.map((item, index) => (
                  <CartCouponItem
                    key={`coupon__item__${index}`}
                    textRemoveFromCart={textRemoveFromCart}
                    item={item}
                    setUpdateCartProcessing={setUpdateCartProcessing}
                    handleRemoveCouponClick={handleRemoveCouponClick}
                  />
                ))}
              </div>
            </div>
          ) : ''}
          {updateCartProcessing && <div className="cartItems__table__spin"><Spin /></div>}

        </div>
        <div className="cartItems__actions">
          <div className="cartItems__copuon">
            <Coupon aplayCoupon={aplayCoupon} setUpdateCartProcessing={setUpdateCartProcessing} couponButton={couponButton} couponPlaceholder={couponPlaceholder} />
            {errorMessage &&
              <div className="cartItems__copuon__message" >
                <p className="error">{errorMessage}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}
export default CartItems
