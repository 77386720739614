import React, { useState } from 'react';
import { v4 } from "uuid";

const Coupon = ({
  couponButton,
  couponPlaceholder,
  aplayCoupon,
  setUpdateCartProcessing
}) => {
  const [couponCode, setCouponCode] = useState('');

  const hadnleApplay = () => {
    const lowerCaseCode = couponCode.toLocaleLowerCase();
    aplayCoupon({
      variables: {
        input: {
          clientMutationId: v4(),
          code: lowerCaseCode
        }
      },
    });
  }

  return (
    <form className="coupon form" onSubmit={(e) => { e.preventDefault(); hadnleApplay(); setUpdateCartProcessing(true) }}>
      <div className="coupon__wrapper">
        <div className="form__input">
          <input type="text" name="code" id="code" placeholder={couponPlaceholder} value={couponCode} onChange={e => setCouponCode(e.target.value)} />
        </div>
        <div className="form__button">
          <button type="submit">{couponButton}</button>
        </div>
      </div>
    </form>
  )
};

export default Coupon;
