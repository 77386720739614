import React from 'react';
import ProductsList from '../../productsList/ProductsList';
import './style.scss';

const CartMoreBuy = ({ products, heading, addToCart }) => {
  return (
    <section className="cartMoreBuy">
      <div className="cartMoreBuy__wrapper">
        <h2 className="cartMoreBuy__headign heading--normal heading--dash heading--center">{heading}</h2>
        <ProductsList action='add' productBoxButton={addToCart} products={products} columns={3} />
      </div>
    </section>
  )
}
export default CartMoreBuy
