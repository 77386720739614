import React from "react"
import "./style.scss"
import ShippingItem from "./ShippingItem"

const Shipping = ({
  shippingMethods,
  handleChange,
  showActionIntegration = false,
  inpostButton = false,
  inpostBoxPlaceholder = false,
  inpostHendleClick = false,
  dpdButton,
  dpdBoxPlaceholder,
  dpdShowPopup,
  dpdInput,
  inpostValue = "",
}) => {

  return (
    <div className="shipping">
      {shippingMethods.length
        ? shippingMethods.map(item => (
            <ShippingItem
              withPickup={item.withPickup}
              key={item.id_shipping}
              handleChange={handleChange}
              title={item.title}
              description={item.description}
              price={item.shiping_price}
              id_shipping={item.id_shipping}
              integration={item.integration}
              showActionIntegration={showActionIntegration}
              inpostButton={inpostButton}
              inpostBoxPlaceholder={inpostBoxPlaceholder}
              inpostHendleClick={inpostHendleClick}
              inpostValue={inpostValue}
              dpdButton={dpdButton}
              dpdBoxPlaceholder={dpdBoxPlaceholder}
              dpdShowPopup={dpdShowPopup}
              dpdInput={dpdInput}
            />
          ))
        : ""}
    </div>
  )
}
export default Shipping
