import React, { useContext, useState, useEffect } from "react"
import { AppContext } from "../../context/AppContext"
import { isEmpty } from "lodash"
import Spin from "../../spin/Spin"
import Shipping from "../../shipping/Shipping"
import { isUserLoggedIn } from "../../../utils/functions"
import Button from "../../button/Button"

import "./style.scss"

const CartSummary = ({
  price,
  vatIncludedText,
  headingCartSummary,
  deliveryText,
  setUpdateCartProcessing,
  updateCartProcessing,
  refetchGetCart,
  allPriceText,
  shippingMethods,
  goToLoginButton,
  shippingMethodsMessage,
  goToCheckoutButton,
  couponText,
}) => {
  const { cart, setShippingID, hasSubscriptionProduct } = useContext(AppContext)
  const [loggedIn, setLoggedIn] = useState(false)

  const handleChange = e => {
    setUpdateCartProcessing(true)
    setShippingID(e.target.value)
    refetchGetCart()
  }

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }
  }, [loggedIn])

  return (
    <section className="cartSummary">
      <h2 className="cartSummary__headign heading--normal heading--dash">
        {headingCartSummary}
      </h2>
      {cart && (
        <>
          <div className="cartSummary__wrapper">
            {cart.totalProductsWithoutCoupnFloat ? (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {price}
                </div>
                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cart?.totalProductsWithoutCoupnPrice,
                    }}
                  ></p>
                </div>
              </div>
            ) : (
              ""
            )}

            {cart.subscription ? (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {price}
                </div>
                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cart?.subscriptionData.totalPrice,
                    }}
                  ></p>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* copuon */}
            {cart?.coupons.length ? (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {couponText}
                </div>
                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cart?.discountAllAmountPrice,
                    }}
                  ></p>
                </div>
              </div>
            ) : (
              ""
            )}
            {shippingMethodsMessage !== 'virtual'? (

            <div className="cartSummary__row">
              <div className="cartSummary__column cartSummary__column--label">
                {deliveryText}
              </div>
              <div className="cartSummary__column cartSummary__column--data">
                {shippingMethodsMessage ? (
                  shippingMethodsMessage
                ) : (
                  <Shipping
                    shippingMethods={shippingMethods}
                    handleChange={e => {
                      handleChange(e)
                    }}
                  />
                )}
              </div>
            </div>
            ) : ''}

            {/*  */}

            {cart.subscription ? (
              cart.totalProductsWithoutCoupnFloat ? (
                <>
                  {" "}
                  <div className="cartSummary__row">
                    <div className="cartSummary__column cartSummary__column--label">
                      {allPriceText}
                    </div>

                    <div className="cartSummary__column cartSummary__column--data">
                      <p
                        className="cartSummary__column__total"
                        dangerouslySetInnerHTML={{
                          __html: cart.totalPriceWithCoupon,
                        }}
                      ></p>
                      <span className="cartSummary__column__vat">
                        {vatIncludedText}
                      </span>
                    </div>
                  </div>
                  <div className="cartSummary__row">
                    <div className="cartSummary__column cartSummary__column--label">
                      {allPriceText}
                    </div>

                    <div className="cartSummary__column cartSummary__column--data">
                      <p
                        className="cartSummary__column__total"
                        dangerouslySetInnerHTML={{
                          __html: cart.subscriptionData.totalPriceWithShipping,
                        }}
                      ></p>
                      <span className="cartSummary__column__vat">
                        {vatIncludedText}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="cartSummary__row">
                  <div className="cartSummary__column cartSummary__column--label">
                    {allPriceText}
                  </div>

                  <div className="cartSummary__column cartSummary__column--data">
                    <p
                      className="cartSummary__column__total"
                      dangerouslySetInnerHTML={{
                        __html: cart.subscriptionData.totalPriceWithShipping,
                      }}
                    ></p>
                    <span className="cartSummary__column__vat">
                      {vatIncludedText}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {allPriceText}
                </div>

                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    className="cartSummary__column__total"
                    dangerouslySetInnerHTML={{
                      __html: cart?.totalPriceWithShippingAndCoupon,
                    }}
                  ></p>
                  <span className="cartSummary__column__vat">
                    {vatIncludedText}
                  </span>
                </div>
              </div>
            )}

            {/* {cart.totalProductsWithoutCoupnFloat ? (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {allPriceText}
                </div>
                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    className="cartSummary__column__total"
                    dangerouslySetInnerHTML={{
                      __html: cart?.totalPriceWithShippingAndCoupon,
                    }}
                  ></p>
                  <span className="cartSummary__column__vat">
                    {vatIncludedText}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {cart.subscription ? (
              <div className="cartSummary__row">
                <div className="cartSummary__column cartSummary__column--label">
                  {allPriceText}
                </div>
                <div className="cartSummary__column cartSummary__column--data">
                  <p
                    className="cartSummary__column__total"
                    dangerouslySetInnerHTML={{
                      __html: cart?.subscriptionData.totalPriceWithShipping,
                    }}
                  ></p>
                  <span className="cartSummary__column__vat">
                    {vatIncludedText}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )} */}
            {/*  */}
            <span className="cartSummary__button">
              {(loggedIn || !hasSubscriptionProduct) ? (
                <Button
                  url={goToCheckoutButton.url}
                  title={goToCheckoutButton.title}
                />
              ) : (
                <Button
                  url={goToLoginButton.url}
                  title={goToLoginButton.title}
                  passState={{
                    cart: true,
                    checkoutLink: goToCheckoutButton.url,
                  }}
                />
              )}
            </span>
          </div>
          {updateCartProcessing && (
            <div className="cartItems__table__spin">
              <Spin />
            </div>
          )}
        </>
      )}
    </section>
  )
}
export default CartSummary
