import { gql } from "@apollo/client";

const APPLY_COUPON = gql`
    mutation APPLY_COUPON($input: ApplyCouponInput!) {
      applyCoupon(input: $input) {
        applied {
          code
          description
          discountAmount
          couponForProduct
          couponForCategory
        }
      }
    }
`;

export default APPLY_COUPON;