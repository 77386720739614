import React, { useEffect, useState, useContext } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getOgImage, getFormattedCart, isBrowser, priceToFloat } from "../../utils/functions"
import CartItems from "../../components/cart/cartItems/CartItems"
import CartMoreBuy from "../../components/cart/cartMoreBuy/CartMoreBuy"
import CartSummary from "../../components/cart/cartSummary/CartSummary"
import OrderStepIndyficator from "../../components/orderStepIndyficator/OrderStepIndyficator"
import { useLazyQuery, useQuery } from "@apollo/client"
import GET_CART from "../../queries/get-cart"
import GET_SHIPPING from "../../queries/get-shipping"
import { AppContext } from "../../components/context/AppContext"

import "./style.scss"
import { WPViewContent } from "../../utils/WPpixel"

const ContactPage = props => {
  const {
    pageContext: { title, seo, uri },
  } = props

  useState(() => {
    WPViewContent("View")
  }, [])

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  const [orderPath, setOrderPath] = useState([])
  const { setCart, shippingID, setShippingID, toogleHasSubscriptionProduct } =
    useContext(AppContext)
  const [updateCartProcessing, setUpdateCartProcessing] = useState(true)
  const [shippingMethods, setShippingMethods] = useState([])
  const [shippingMethodsMessage, setShippingMethodsMessage] = useState([])

  const [productList, setProductList] = useState([])
  const [tmpDataGetCart, setTmpDataGetCart] = useState(
    isBrowser() ? JSON.parse(localStorage.getItem("woo-next-cart")) : []
  )

  const {
    couponButton,
    couponPlaceholder,
    headingYorProducts,
    tabelHeadingAllPrice,
    tabelHeadingPrice,
    tabelHeadingProduct,
    tabelHeadingQuantity,
    products,
    headingOtherBuy,
    headingCartSummary,
    price,
    couponText,
    vatIncludedText,
    deliveryText,
    addToCart,
    textRemoveFromCart,
    tabelHeadingCouponName,
    tabelHeadingCouponDiscout,
    allPriceText,
    goToLoginButton,
    goToCheckoutButton,
  } = props.pageContext.acfCart

  // Get Cart Data.
  // const [refetchGetCart, { loading, error, data }] = useLazyQuery(GET_CART, {
  const {
    loading,
    error,
    data: dataGetCart,
    refetch: refetchGetCart,
  } = useQuery(GET_CART, {
    variables: {
      country: lang.toUpperCase(),
      shippingID: shippingID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(dataGetCart)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))

      if (dataGetCart) {
        setTmpDataGetCart(dataGetCart)
        setCart(updatedCart)
      }
      setUpdateCartProcessing(false)
    },
  })

  const wrapperRefetchGetCart = () => {
    setUpdateCartProcessing(true)
    refetchGetCart()
  }

  const [
    getShipping,
    { loading: loadingShipping, error: errorShipping, data: dataShipping },
  ] = useLazyQuery(GET_SHIPPING, {
    // const { loading: loadingShipping, error: errorShipping, data: dataShipping, refetch: getShipping } = useQuery(GET_SHIPPING, {

    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: () => {
      setShippingMethods(dataShipping?.shippingZone?.node)
      setShippingMethodsMessage(dataShipping?.shippingZone?.message)

      if (!shippingID) {
        setShippingID(dataShipping.shippingZone.node[0]["id_shipping"])
      }
    },
  })
  useEffect(() => {
    if (lang === "pl") {
      setOrderPath(props.pageContext.orderPathPL)
    }
    if (lang === "en") {
      setOrderPath(props.pageContext.orderPathEN)
    }
    if (lang === "de") {
      setOrderPath(props.pageContext.orderPathDE)
    }
    if (lang === "fr") {
      setOrderPath(props.pageContext.orderPathFR)
    }

    let tmp = []

    products.forEach(element => {
      tmp.push({ ...element.product })
    })

    setProductList(tmp)
  }, [])

  useEffect(() => {
    if (tmpDataGetCart) {
      let value = 0
      // console.log(tmpDataGetCart)

      setUpdateCartProcessing(true)

      if (+tmpDataGetCart?.cart?.subscriptionTotalWithoutCoupnFloat) {
        value = +tmpDataGetCart?.cart?.subscriptionTotalWithoutCoupnFloat
        getShipping({
          variables: {
            country: lang.toUpperCase(),
            total: +tmpDataGetCart?.cart?.subscriptionTotalWithoutCoupnFloat,
          },
        })
        toogleHasSubscriptionProduct(true)
      } else {
        toogleHasSubscriptionProduct(false)
        value = +tmpDataGetCart?.cart?.totalWithoutCoupnFloat
        getShipping({
          variables: {
            country: lang.toUpperCase(),
            total: +tmpDataGetCart?.cart?.totalWithoutCoupnFloat,
          },
        })
      }
    }

    let gtmProducts = []
    tmpDataGetCart?.cart?.contents?.nodes.forEach(element => {
      let subElement = element.product.node
      gtmProducts.push({
        id: subElement.productId,
        item_id: subElement.productId,
        sku: subElement.productId,
        item_name: subElement.name,
        name: subElement.name,
        item_brand: "",
        price: priceToFloat(subElement.price),
        item_category: subElement.category,
        category: subElement.category,
        quantity: element.quantity,
        stocklevel: null,
      })
    })
    // tmpDataGetCart?.cart?.contents.nodes[0].product.node.forEach(element => {
    // });

    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currencyCode: "PLN",
          currency: "PLN",
          value: tmpDataGetCart?.cart?.totalWithoutCoupnFloat,
          items: gtmProducts,
          cart:gtmProducts,
        },
      })
    }
  }, [tmpDataGetCart])

  // useEffect(() => {
  //   console.log('asd');
  //   const updatedCart = getFormattedCart(tmpDataGetCart);
  //   localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));
  //   setCart(updatedCart);
  //   // setTimeout(() => {
  //   //   getShipping({
  //   //     variables: {
  //   //       country: lang.toUpperCase(),
  //   //       total: +tmpDataGetCart?.cart?.totalWithoutCoupnFloat,
  //   //     },
  //   //   })
  //   // }, 300);
  // }, [shippingID])

  return (
    <Layout lang={lang} page="cart" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"cart"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          <div className="cart">
            <OrderStepIndyficator
              orderPath={orderPath.stepIndyficator}
              activeStep={0}
            />
            <div className="container">
              <div className="cart__columns">
                {/* <div className="cart__mainColumn"> */}
                <div className="cart__CartItems">
                  <CartItems
                    updateCartProcessing={updateCartProcessing}
                    refetchGetCart={wrapperRefetchGetCart}
                    addToCart={addToCart}
                    tabelHeadingCouponName={tabelHeadingCouponName}
                    tabelHeadingCouponDiscout={tabelHeadingCouponDiscout}
                    couponButton={couponButton}
                    couponPlaceholder={couponPlaceholder}
                    headingYorProducts={headingYorProducts}
                    tabelHeadingAllPrice={tabelHeadingAllPrice}
                    tabelHeadingPrice={tabelHeadingPrice}
                    tabelHeadingProduct={tabelHeadingProduct}
                    tabelHeadingQuantity={tabelHeadingQuantity}
                    setUpdateCartProcessing={setUpdateCartProcessing}
                    textRemoveFromCart={textRemoveFromCart}
                  />
                </div>
                <div className="cart__CartMoreBuy">
                  <CartMoreBuy
                    products={productList}
                    heading={headingOtherBuy}
                    addToCart={addToCart}
                  />
                </div>

                {/* </div> */}
                {/* <div className="cart__summary"> */}
                <div className="cart__CartSummary">
                  <CartSummary
                    refetchGetCart={wrapperRefetchGetCart}
                    price={price}
                    allPriceText={allPriceText}
                    vatIncludedText={vatIncludedText}
                    headingCartSummary={headingCartSummary}
                    deliveryText={deliveryText}
                    couponText={couponText}
                    goToLoginButton={goToLoginButton}
                    goToCheckoutButton={goToCheckoutButton}
                    setUpdateCartProcessing={setUpdateCartProcessing}
                    updateCartProcessing={updateCartProcessing}
                    shippingMethods={shippingMethods}
                    setShippingMethods={setShippingMethods}
                    shippingMethodsMessage={shippingMethodsMessage}
                    shippingID={shippingID}
                  />
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ContactPage
